<template>
  <div>
    <Wrapper>
      <Sidebar slot="sidebar"/>
      <Navbar slot="navbar"/>
      <Footer slot="footer" />
      <div slot="content">
        <!-- <input type="text" v-model="filters.name.value"> -->
        <div class="container">
         <div style="display:flex; min-height:80vh; align-items:center">
        <div style="max-width:750px; margin:0 auto">
            <div>
              <div class="text-center">
                 <img src="../../../assets/images/sumBg.png" height="300" v-if="windowWidth > 445">
                <img src="../../../assets/images/sumBg.png" height="150" v-if="windowWidth < 445">
              </div>

              <p class="text-center pt-4 pb-2"><b>You have successfully invested </b></p>
              <p class="text-center"><b>And your upfront interest has been paid into your wallet. </b></p>
            </div>

            <div class="vest-btn mt-4 point-cursor" style="margin:0 auto"  @click="sendToAmountView">
              <div class="first-div" >
                <h6 style="color:#000">
                  <b>Create new Vestlock</b>
                </h6>
                <p style="color:#828282" class="pt-2">{{vestPortfolio.length}} Lockdown(s)</p>
              </div>

              <div class="text-right sec-div">
                <img src="../../../assets/images/greywallet.png" height="20">
              </div>
            </div>

            <div class="vest-btn mt-4 point-cursor" style="margin: 0 auto" @click="gotoVestPortfolio">
              <div class="first-div" >
                <h6 style="color:#000">
                  <b>See your Vestlock portfolio</b>
                </h6>
              </div>

              <div class="sec-div text-right">
                <img src="../../../assets/images/portf2.png" height="20">
              </div>
            </div>

          </div>
        </div>
        </div>
      </div>
    </Wrapper>
  </div>
</template>

<script>
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import Footer from "../../partials/Footer";
import Wrapper from "../../partials/Wrapper";
import trans from "../../../../translations";

import { commonJs } from "../mixins/commonJs.js";

import { mapState } from "vuex";

import moment from "moment";

export default {
  mixins: [commonJs],
  data() {
    return {
      tran: {}
    };
  },
  methods: {
  
    
    gotoVestPortfolio(){

        this.$router.push("/vest/portfolio");
        this.$store.commit("auth/saveVestRouteVisited", "/vest/portfolio")

    },
       sendToAmountView() {

        this.$store.commit("auth/saveVestRouteVisited", "/vest/amount")
      this.$router.push("/vest/amount");
    },
  },
  components: {
    Navbar,
    Footer,
    Sidebar,
    Wrapper
  },

  computed: {
    ...mapState({
      userBal: state => state.auth.userProfileInfo.balance,
       vestPortfolio: state => state.market.vestPortfolio,
       vestLockData: state => state.market.vestLockData,
    }),

    returnAmountOfPenalty() {
      return this.penaltyAmount;
    }
  },
  mounted() {
    let heading = {
      main: this.tran.vest_lock
    };

    this.$store.commit("market/savePageHeading", heading);
     this.$store.commit("auth/saveVestRouteVisitedAfterClickingPortfolio", this.$route.path)
       this.$store.dispatch("market/getVestPortfolio");

         localStorage.setItem("vestLockDataFromLocalStorage", false )
   localStorage.setItem("investAmount",false)
  },

  beforeCreate() {
    this.$store.dispatch("market/getPortfolio");
    this.$store.commit("market/checkSearchObject", "portfolio");
        let userAmount = localStorage.getItem("investAmount");

    if(userAmount == 'false'){

  this.$router.push('/vest/home')
}
  },

  beforeDestroy() {
    this.$store.commit("market/startPreloader");

    this.$store.commit("market/startIsFetching");

    this.$store.commit("market/removePageHeading");

  }
};
</script>

<style scoped>
</style>
