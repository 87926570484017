<template>
<div>
    <VestFinal />
</div>
</template>

<script>
import VestFinal from '../../components/dashboard/vestfolder/VestFinal.vue'
export default {
    components: {
        VestFinal
    }
}
</script>

